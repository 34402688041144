import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Background from "../images/Bg6.png"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

class ServicePageDigitalMarketingTemplate extends React.Component {
    render() {
        const digitalmarketingpage = this.props.data.contentfulDigitalmarketing
        let title = digitalmarketingpage.cityName + " Digital Marketing | Website Marketing Company | SEO Agency"
        let keywords = [digitalmarketingpage.cityName + " Digital Marketing", digitalmarketingpage.cityName + " Website Marketing", digitalmarketingpage.cityName + " Website Advertisement", digitalmarketingpage.cityName + " website ads", digitalmarketingpage.cityName + " Website leads"]
        let location = "/" + digitalmarketingpage.slug
        let description = "Pyxel Development offers professional " + digitalmarketingpage.cityName + " Digital Marketing, " + digitalmarketingpage.cityName + " Website Marketing, " + digitalmarketingpage.cityName + " Website Advertisement, and " + digitalmarketingpage.cityName + " Website Leads services that are guaranteed to boost your business to the next level at an affordable rate."
        let isProduct = true
        let productName = digitalmarketingpage.cityName + " Digital Marketing"
        let sku = "10000002"
        return (
            <Layout>
                <Seo title={title} keywords={keywords} location={location} description={description} isProduct={isProduct} productName={productName} sku={sku} />
                <div className="bg-no-repeat bg-cover bg-left"
                    style={{
                        backgroundImage: `url(${Background})`,
                        width: '100%',
                        height: '100%',
                        backgroundAttachment: 'absolute',
                    }}>
                    <div className="container grid gap-x-5 lg:grid-cols-2 text-white font-bold p-10 pt-36 mx-auto xl:py-40">
                        <div className="flex justify-center lg:justify-end">
                            <div>
                                <p>SERVICE</p>
                                <h1 className="text-5xl md:text-6xl xl:text-7xl font-pixeboy" style={{ lineHeight: "80%" }}>
                                    {digitalmarketingpage.cityName}<br />{digitalmarketingpage.serviceType}
                                </h1>
                                <h2 className="text-md md:text-xl max-w-lg mt-7">
                                    REACH YOUR INTENDED AUDIENCE TO GAIN THE RECOGNITION YOU DESERVE
                                </h2>
                                <div className="max-w-xl font-normal text-xs md:text-base">
                                    <p className="mt-7">
                                        Having a relevant online marketing strategy is a widely underestimated tool in the business industry. Digital marketing is one of the most effective tools
                                        that is proven to generate three times the amount of leads than the current traditional marketing strategies.<br /><br />
                                        Pyxel Development has been growing as a top {digitalmarketingpage.cityName} Digital Marketing Agency because we focus on providing digital marketing strategies that are organized,
                                        effective, and strategically implemented to provide you with more leads and an increase of your “Return On Investment”
                                    </p>
                                    <div className="hidden lg:block mt-10 md:mt-12 xl:mt-28">
                                        <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                            <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                                <span>Request Quote</span>
                                            </button>
                                        </Link>
                                        <div className="hover:text-gray-600 mt-4">
                                            <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">
                                                Call Us: (916) 821-9044
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center pt-10 lg:pt-0 mx-auto max-w-xs md:max-w-md lg:max-w-2xl">
                            <StaticImage src="../images/digital-marketing-1.png" placeholder="none" title={digitalmarketingpage.cityName + " Digital Marketing agency"} alt={digitalmarketingpage.cityName + " Digital Marketing agency"} />
                        </div>
                        <div className="lg:hidden mx-auto mt-10 md:mt-12 xl:mt-28">
                            <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                    <span>Request Quote</span>
                                </button>
                            </Link>
                            <div className="hover:text-gray-600 mt-4">
                                <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">
                                    Call Us: (916) 821-9044
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-banner">
                    <div className="py-10 md:py-24 px-4 md:px-10">
                        <div className="text-white px-5 text-center">
                            <div className="mx-auto max-w-5xl">
                                <h2 className="mx-auto text-2xl font-bold md:text-4xl">
                                    WHY CHOOSE PYXEL DEVELOPMENT?
                                </h2>
                                <p className="text-xs mx-auto lg:text-sm 2xl:text-base mt-8">
                                    The first step to a successful website is ensuring that it will reach your intended audience. There are many key factors that play a role in the ranking
                                    and growth of a website. Many Digital Strategies that are commonly implemented are becoming outdated and unreliable. Pyxel Development is a digital
                                    marketing agency which offers the latest industry leading digital marketing strategies and technologies to guarantee the exponential growth of your business.
                                </p>
                            </div>
                        </div>
                        <div className="flex order-2 md:justify-end md:order-1 pt-7">
                            <StaticImage src="../images/digital-marketing-2.png" className="mx-auto max-w-xs" placeholder="none" title={digitalmarketingpage.cityName + " Digital Marketing company"} alt={digitalmarketingpage.cityName + " Digital Marketing company"} />
                        </div>
                    </div>
                </div>

                <div className="bg-white py-10 md:py-24 px-5">
                    <div className="container mx-auto">
                        <h2 className="text-center font-bold text-2xl md:text-4xl">DIGITAL MARKETING ACTION-PLAN</h2>
                        <div className="text-center mx-auto max-w-xs py-5 mb-3 md:mt-10 md:grid md:grid-cols-2 xl:grid-cols-4 space-y-8 md:space-y-0 md:gap-16 md:max-w-full">
                            <div>
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/marketing.png" placeholder="none" title={digitalmarketingpage.cityName + " digital marketing advertisements"} alt={digitalmarketingpage.cityName + " digital marketing advertisements"} />
                                </div>
                                <h3 className="font-bold text-lg py-3">ADVERTISING</h3>
                                <p className="text-sm md:text-base">Online advertisement is a powerful tool whose importance is indescribable. We offer services that include Pay-per-click, Google Ads, Facebook Ads and Instagram Ads.</p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-2" src="../images/marketing.png" placeholder="none" title={digitalmarketingpage.cityName + " digital marketing advertisements"} alt={digitalmarketingpage.cityName + " digital marketing advertisements"} />
                                </div>
                            </div>
                            <div>
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/research.png" placeholder="none" title={digitalmarketingpage.cityName + " digital marketing keywords"} alt={digitalmarketingpage.cityName + " digital marketing keywords"} />
                                </div>
                                <h3 className="font-bold text-lg py-3">KEYWORD RESEARCH</h3>
                                <p className="text-sm md:text-base">Keywords are critical to ensure that your content is optimized to improve your websites ranking and increase visibility to your
                                    target audience. Pyxel Development offers professional keyword research and content implementation to make your organization stand out.</p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-2 " src="../images/research.png" placeholder="none" title={digitalmarketingpage.cityName + " digital marketing keywords"} alt={digitalmarketingpage.cityName + " digital marketing keywords"} />
                                </div>
                            </div>
                            <div>
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/strategy.png" placeholder="none" title={digitalmarketingpage.cityName + " digital marketing strategy"} alt={digitalmarketingpage.cityName + " digital marketing strategy"} />
                                </div>
                                <h3 className="font-bold text-lg py-3">CONTENT STRATEGIES</h3>
                                <p className="text-sm md:text-base">Our Digital Marketing services include a unique content strategy tailored to your business. We provide recommendations and
                                    implementation for all content aspects, ranging from social media posts and advertisements to blog posts and main website content</p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-2" src="../images/strategy.png" placeholder="none" title={digitalmarketingpage.cityName + " digital marketing strategy"} alt={digitalmarketingpage.cityName + " digital marketing strategy"} />
                                </div>
                            </div>
                            <div>
                                <div className="hidden md:flex">
                                    <StaticImage className="mb-2 w-24 lg:w-32 mx-auto" src="../images/social-media.png" placeholder="none" title={digitalmarketingpage.cityName + " digital marketing social media"} alt={digitalmarketingpage.cityName + " digital marketing social media"} />
                                </div>
                                <h3 className="font-bold text-lg py-3">SOCIAL MEDIA</h3>
                                <p className="text-sm md:text-base">Pyxel Development includes services to manage and grow your organization with high quality content, specifically tailored
                                    for your business. Implementation occurs through social media platforms like Facebook, Instagram, and any other platforms that you require.</p>
                                <div className="md:hidden">
                                    <StaticImage className="w-24 mt-6 mb-2" src="../images/social-media.png" placeholder="none" title={digitalmarketingpage.cityName + " digital marketing social media"} alt={digitalmarketingpage.cityName + " digital marketing social media"} />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center md:-mt-10">
                        </div>
                    </div>
                </div>

                <div className="bg-offblue text-center py-10 md:py-24">
                    <h2 className="text-center text-white text-2xl md:text-4xl font-bold">WE TAKE CARE OF IT ALL</h2>
                    <div className="lg:grid lg:grid-cols-3 container mx-auto gap-24 mt-7 px-10 space-y-8 md:space-y-0">
                        <div>
                            <h3 className="font-bold text-lg py-3">ATTRACT NEW LEADS</h3>
                            <p>Gaining customer leads is the main priority of a website. We incorporate our industry leading SEO, Backlink, and
                                Advertisement strategies to increase your website traffic and grow your customer conversion rate.
                            </p>
                            <StaticImage className="mb-2 w-24 lg:w-32 mx-auto mt-5 md:mt-10" src="../images/magnetism.png" placeholder="none" title={digitalmarketingpage.cityName + " digital marketing leads"} alt={digitalmarketingpage.cityName + " digital marketing leads"} />
                        </div>
                        <div>
                            <h3 className="font-bold text-lg py-3">BLOGS</h3>
                            <p>Effective blogging is required for website growth. Pyxel Development provides consistent high quality
                                content to generate new leads, more website traffic, and backlinks to increase the rate of growth for your business.
                            </p>
                            <StaticImage className="mb-2 w-24 lg:w-32 mx-auto mt-5 md:mt-10 xl:mt-5 2xl:mt-10" src="../images/blogging.png" placeholder="none" title={digitalmarketingpage.cityName + " digital marketing blog"} alt={digitalmarketingpage.cityName + " digital marketing blog"} />
                        </div>
                        <div>
                            <h3 className="font-bold text-lg py-3">STATISTICS</h3>
                            <p>To help our customers visualize the effect of our industry leading strategies, we provide consistent statistics, rankings, and ratings that reflect the
                                growth of the organization and how it compares to the competition.
                            </p>
                            <StaticImage className="mb-2 w-24 lg:w-32 mx-auto mt-2 md:mt-6" src="../images/statistical.png" placeholder="none" title={digitalmarketingpage.cityName + " digital marketing statistics"} alt={digitalmarketingpage.cityName + " digital marketing statistics"} />
                        </div>
                    </div>
                    <div className="flex justify-center md:-mt-10">
                        <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                            <button className="m-5 relative h-10 px-6 text-xs text-black transition-colors bg-transparent rounded-lg border border-black focus:shadow-outline hover:bg-white md:mt-20">
                                <span>Get Started <b>TODAY!</b></span>
                            </button>
                        </Link>
                    </div>
                </div>
            </Layout>
        )
    }
}
export default ServicePageDigitalMarketingTemplate
export const pageQuery = graphql`
query ContentfulDigitalmarketingBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulDigitalmarketing( slug: {eq: $slug }) {
        title
        cityName
        boldName
        serviceType
        slug
    }
}
`